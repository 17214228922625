import type {
  Questionnaire,
  QuestionnaireType,
} from '@dialogue/services/dist/er/model'
import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'
interface State {
  fetching: boolean
  error: Error | null
  type: QuestionnaireType | null
  data: Questionnaire[]
}

export const INITIAL_STATE: State = {
  fetching: false,
  error: null,
  type: null,
  data: [],
}

export const { actions: questionnairesGraphActions, reducer } = createSlice({
  name: '@@questionnaires/GRAPH',
  initialState: INITIAL_STATE,
  reducers: {
    get: (
      state,
      _action: PayloadAction<{
        memberId: number
        type: QuestionnaireType
      }>,
    ) => {
      state.error = null
      state.fetching = true
    },
    error: (state, { payload }: PayloadAction<Error>) => {
      state.fetching = false
      state.error = payload
    },
    receive: (
      state,
      {
        payload,
      }: PayloadAction<{ data: Questionnaire[]; type: QuestionnaireType }>,
    ) => {
      state.fetching = false
      state.error = null
      state.data = payload.data
      state.type = payload.type
    },
  },
})

export const selectQuestionnairesGraphFetching = (state: ReduxState) =>
  state.questionnairesGraph.fetching

export const selectQuestionnairesGraphError = (state: ReduxState) =>
  state.questionnairesGraph.error

export const selectSelectedQuestionnaireGraph = (state: ReduxState) =>
  state.questionnairesGraph.data

export default reducer
