import {
  type OutputAppointmentType as AppointmentType,
  PacEncounterType,
} from '@dialogue/timekeeper'
import {
  createSelector,
  createSlice,
  type Draft,
  type PayloadAction,
} from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

interface TypesState {
  types: AppointmentType[] | null
  fetching: boolean
  errorFetching: Error | null
}

interface State {
  all: TypesState
  providers: Record<number, TypesState>
}

const makeState = (): TypesState => {
  return {
    types: null,
    fetching: false,
    errorFetching: null,
  }
}

const getOrInitState = (
  state: Draft<State> | State,
  providerId: number,
): TypesState => {
  return (state.providers[providerId] =
    state.providers[providerId] || makeState())
}

const INITIAL_STATE: State = {
  all: makeState(),
  providers: {},
}

export const { actions: timekeeperAppointmentTypeActions, reducer } =
  createSlice({
    name: '@@timekeeper/appointmentTypes',
    initialState: INITIAL_STATE,
    reducers: {
      getAll(state) {
        state.all.fetching = true
        state.all.errorFetching = null
      },
      receivedAll(
        state,
        {
          payload,
        }: PayloadAction<{
          types: AppointmentType[]
        }>,
      ) {
        state.all.fetching = false

        state.all.types = payload.types
      },
      getForProvider(
        state,
        { payload }: PayloadAction<{ providerId: number }>,
      ) {
        const providerState = getOrInitState(state, payload.providerId)
        providerState.fetching = true
        providerState.errorFetching = null

        providerState.types = null
      },
      receivedForProvider(
        state,
        {
          payload,
        }: PayloadAction<{
          types: AppointmentType[]
          providerId: number
        }>,
      ) {
        const providerState = getOrInitState(state, payload.providerId)
        providerState.fetching = false

        providerState.types = payload.types
      },
      erroredReceivingTypes(state, { payload }: PayloadAction<Error>) {
        state.all.fetching = false
        state.all.errorFetching = payload
      },
    },
  })

export const selectAppointmentTypes = (state: ReduxState) =>
  state.timekeeper.appointmentTypes.all.types

export const selectAppointmentTypesFetching = (state: ReduxState) =>
  state.timekeeper.appointmentTypes.all.fetching

export const selectAppointmentTypesErrorFetching = (state: ReduxState) =>
  state.timekeeper.appointmentTypes.all.errorFetching

export const selectStateForProvider = (state: ReduxState, providerId: number) =>
  state.timekeeper.appointmentTypes.providers?.[providerId]

export const selectAppointmentTypesForProvider = createSelector(
  selectStateForProvider,
  (state: TypesState) => state?.types,
)

export const selectAppointmentTypesFetchingForProvider = createSelector(
  selectStateForProvider,
  (state: TypesState) => state?.fetching,
)

export const selectAppointmentTypesErrorFetchingForProvider = createSelector(
  selectStateForProvider,
  (state: TypesState) => state?.errorFetching,
)

export const selectFollowupAppointmentTypes = createSelector(
  selectAppointmentTypes,
  (typeList) =>
    typeList &&
    typeList.filter(
      (appointment) => appointment.encounter_type === PacEncounterType.Followup,
    ),
)

export const selectAppointmentTypeDisplayName = createSelector(
  selectAppointmentTypes,
  (_state: ReduxState, appointmentType: string) => appointmentType,
  (appointmentTypes, appointmentType) =>
    appointmentTypes?.find(
      (entry) => entry.appointment_type === appointmentType,
    )?.display_name || appointmentType?.replace(/_/g, ' '),
)

export default reducer
