import type {
  InputsValue,
  ItemResponseDictStrNotepadModelsTemplateResolvedTemplate,
  LanguageCode,
  LocalizedValue,
  Note,
  ResolvedTemplate,
  SectionsInner,
} from '@dialogue/notepad-client'
import type { PayloadAction } from '@reduxjs/toolkit'

export type PatientAction<T extends Record<any, any> = {}> = PayloadAction<
  { patientId: string | number } & T
>

export enum InputType {
  SELECT = 'select',
  MULTISELECT = 'multiselect',
  TEXT = 'text',
  MULTILINE = 'multiline',
  RADIO = 'radio',
  YES_NO = 'yes_no',
  NUMBER = 'number',
  MEDICATIONS = 'medication',
  MEMBER_AND_PROVIDER_INFORMATION = 'member_and_provider_information',
  DATE_RANGE = 'date_range',
  DATE = 'date',
  PHONE = 'phone',
  MEMBER_ELIGIBILITY_INFORMATION = 'member_eligibility_information',
  MEMBER_HEALTH_PROFILE_INFORMATION = 'member_health_profile_information',
}

export type Localized<T> = T extends LocalizedValue
  ? string
  : T extends Record<any, any>
    ? { [K in keyof T]: Localized<T[K]> }
    : T

export enum Operation {
  FETCH = 'fetching',
  UPDATE = 'updating',
  CREATE = 'creating',
  DELETE = 'deleting',
  FINALIZE = 'finalizing',
}

export type LocalizedNoteTemplate = Localized<ResolvedTemplate>

export type NoteCreationData = {
  values: Record<string, unknown>
  template_id: string
  sub_note?: NoteCreationData
  parent_note_id?: number
  tempId?: string
  index?: number
}

type SubNoteCreationData = {
  template_id: string
  values: Record<string, unknown>
  tempId?: string
  index?: number
}

export type NoteCreationDataComplete = {
  values: Record<string, unknown>
  guardian_id: number
  template_id: string
  /*
    TODO: change to sub_notes
    requires adding an imparative handle to get sub-note values from the parent note
  */
  sub_note?: SubNoteCreationData
  episode_id: string
  language: LanguageCode
  parent_note_id?: number
}

export interface NoteWithTempId extends Note {
  tempId?: string
}

export type LocalizedNoteSection = Localized<SectionsInner>

export type LocalizedNoteSections = Array<Localized<SectionsInner>>

export type TemplateRecord =
  ItemResponseDictStrNotepadModelsTemplateResolvedTemplate['data']

export type InputConfig = InputsValue
