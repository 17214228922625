// @ts-strict-ignore
import { all, call, put, select } from 'typed-redux-saga/macro'

import { pharmaciesApi } from 'app/redux/api/emergency-room/pharmacies'
import * as inputHealthActions from 'app/redux/input-health/actions'
import { pharmaciesActions } from 'app/redux/medical-profile/pharmacies'
import { selectPharmaciesFromCache } from 'app/redux/medical-profile/selectors'
import { selectPatientPreferredPharmacyId } from 'app/redux/patients/selectors'
import { verifyRecord } from 'app/sagas/input-health'
import { initEmeraldV2Client, takeKeyedLatest } from 'app/sagas/utils'

export function* getEmeraldPharmacy({
  payload,
}: ReturnType<typeof pharmaciesActions.get>) {
  try {
    const emerald = yield* call(initEmeraldV2Client)
    const pharmacy = yield* call(emerald.getPharmacy, payload.pharmacyId)
    yield* put(pharmaciesActions.receiveEmerald(pharmacy))
  } catch (e) {
    yield* put(pharmaciesActions.error(e))
  }
}

export function* getPharmacy({
  payload,
}: ReturnType<typeof pharmaciesActions.get>) {
  try {
    const pharmacyPromise = yield* put(
      // @ts-ignore ThunkActions are valid Actions
      yield* call(
        pharmaciesApi.endpoints.getPharmacy.initiate,
        { pharmacyId: Number(payload.pharmacyId) },
        {
          forceRefetch: false,
        },
      ),
    )

    yield pharmacyPromise

    const { data } = yield* select(
      selectPharmaciesFromCache(Number(payload.pharmacyId)),
    )

    yield* put(pharmaciesActions.receive(data))
  } catch (e) {
    yield* put(pharmaciesActions.error(e))
  }
}

export function* getPreferredPharmacy({
  payload,
}: ReturnType<typeof pharmaciesActions.getPreferred>) {
  try {
    const { data } = yield* call(
      verifyRecord,
      inputHealthActions.verifyRecord(payload.patientId),
    )

    const emeraldPharmacyId: string | null = data?.preferred_pharmacy_id
    const pharmacyId: string | null = yield* select(
      selectPatientPreferredPharmacyId,
      payload.patientId,
    )

    // Prioritize IH pharmacy even if one exists in ER
    if (emeraldPharmacyId) {
      yield* call(
        getEmeraldPharmacy,
        pharmaciesActions.get({ pharmacyId: emeraldPharmacyId }),
      )
    } else if (pharmacyId) {
      yield* call(getPharmacy, pharmaciesActions.get({ pharmacyId }))
    } else {
      yield* put(pharmaciesActions.receiveEmerald(null))
      yield* put(pharmaciesActions.receive(null))
    }
  } catch (error) {
    yield* put(pharmaciesActions.error(error))
  }
}

export default function* pharmaciesSagas() {
  yield* all([
    takeKeyedLatest(
      pharmaciesActions.get,
      ({ payload }) => payload.pharmacyId,
      getEmeraldPharmacy,
    ),
    takeKeyedLatest(
      pharmaciesActions.getPreferred,
      (action) => action.payload.patientId,
      getPreferredPharmacy,
    ),
  ])
}
