import type {
  DeleteMemberDocResponse,
  ItemDownloadUrlResponse,
  ItemMemberDocument,
  ItemSyncExportResponse,
  MemberApiDeleteMemberDocumentRequest,
  MemberApiExportMemberInformationRequest,
  MemberApiExportMemberInformationSingleNoteRequest,
  MemberApiGetDocumentsRequest,
  MemberApiGetFileDownloadUrlRequest,
  MemberApiPatchDocumentRequest,
  MemberApiReviewDocumentRequest,
  MemberDocument,
  PageMemberDocument,
} from '@dialogue/document-center'
import { decamelizeKeys } from 'humps'

import { transformMemberDocumentFilters } from '../utils'

import { Tags, documentCenterApi } from './api'

export const memberDocumentsApi = documentCenterApi.injectEndpoints({
  endpoints: (builder) => ({
    getMemberDocuments: builder.query<
      MemberDocument[],
      MemberApiGetDocumentsRequest
    >({
      query: ({ memberId, ...params }) => ({
        url: `/member/${memberId}/`,
        params: transformMemberDocumentFilters(params),
      }),
      transformResponse: (response: PageMemberDocument) => response.data,
      providesTags: (_result, _error, { memberId }) => [
        { type: Tags.MemberDocument, id: memberId },
      ],
    }),
    getPaginatedMemberDocuments: builder.query<
      PageMemberDocument,
      MemberApiGetDocumentsRequest
    >({
      query: ({ memberId, ...params }) => ({
        url: `/member/${memberId}/`,
        params: transformMemberDocumentFilters(params),
      }),
      providesTags: (_result, _error, { memberId }) => [
        { type: Tags.MemberDocument, id: memberId },
      ],
    }),

    updateMemberDocument: builder.mutation<
      ItemMemberDocument,
      MemberApiPatchDocumentRequest
    >({
      query: ({ memberId, documentId, patchMemberDocRequest }) => ({
        url: `/member/${memberId}/${documentId}`,
        method: 'PATCH',
        body: decamelizeKeys(patchMemberDocRequest),
      }),
      invalidatesTags: (_result, _error, { memberId }) => [
        { type: Tags.MemberDocument, id: memberId },
        // also invalidate incoming/outgoing faxes.
        { type: Tags.IncomingFaxDocument, id: 'LIST' },
        { type: Tags.OutgoingFaxDocument, id: 'LIST' },
      ],
    }),

    deleteMemberDocument: builder.mutation<
      DeleteMemberDocResponse,
      MemberApiDeleteMemberDocumentRequest
    >({
      query: ({ memberId, documentId }) => ({
        url: `/member/${memberId}/${documentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { memberId }) => [
        { type: Tags.MemberDocument, id: memberId },
      ],
    }),

    reviewMemberDocument: builder.mutation<
      null,
      MemberApiReviewDocumentRequest
    >({
      query: ({ memberId, documentId }) => ({
        url: `/member/${memberId}/${documentId}/review`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, { memberId }) => [
        { type: Tags.MemberDocument, id: memberId },
      ],
    }),

    getMemberDocumentDownloadUrl: builder.query({
      query: ({
        memberId,
        documentId,
      }: MemberApiGetFileDownloadUrlRequest) => ({
        url: `/member/${memberId}/${documentId}/download-url`,
      }),
      transformResponse: (response: ItemDownloadUrlResponse) =>
        response.data.url,
    }),

    exportMemberInformation: builder.mutation<
      ItemSyncExportResponse,
      MemberApiExportMemberInformationRequest
    >({
      query: ({ memberId, bodyMemberExportMemberInformation }) => ({
        method: 'POST',
        url: `/member/${memberId}/export`,
        body: bodyMemberExportMemberInformation,
      }),
    }),

    exportMemberNoteInformation: builder.mutation<
      ItemSyncExportResponse,
      MemberApiExportMemberInformationSingleNoteRequest
    >({
      query: ({ memberId, noteId }) => ({
        method: 'POST',
        url: `/member/${memberId}/export/note/${noteId}`,
      }),
    }),
  }),
})

export const {
  useGetMemberDocumentsQuery,
  useGetPaginatedMemberDocumentsQuery,
  useUpdateMemberDocumentMutation,
  useDeleteMemberDocumentMutation,
  useReviewMemberDocumentMutation,
  useGetMemberDocumentDownloadUrlQuery,
  useLazyGetMemberDocumentDownloadUrlQuery,
  useExportMemberInformationMutation,
  useExportMemberNoteInformationMutation,
} = memberDocumentsApi
