import { useCallback, useContext, useMemo } from 'react'

import { Collapse, Table, Typography, type TablePaginationConfig } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import type { TableRowSelection } from 'antd/es/table/interface'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors } from 'app/theme'

import { formatDocumentDate } from '../documents/helpers'

import { MemberExportDrawerContext } from './drawer'
import { ExportRecordSummary } from './export-record-summary'
import type { SharedExportProperties } from './types'
import { FOOTER_HEIGHT } from './utils'

interface ExportSectionTableProps<T extends SharedExportProperties> {
  memberId: number
  dataSource: T[]
  identifier: 'notes' | 'documents'
  onSelectionChange: (rows: T[]) => void
  pagination: TablePaginationConfig
  onChange: (pagination: TablePaginationConfig) => void
  defaultOpen?: boolean
  loading?: boolean
}

export const ExportSectionTable = <T extends SharedExportProperties>({
  memberId,
  dataSource,
  identifier,
  onSelectionChange,
  pagination,
  onChange,
  defaultOpen = true,
  loading = false,
}: ExportSectionTableProps<T>) => {
  const { selectedExportRecords } = useContext(MemberExportDrawerContext)
  const { t } = useTranslation()

  const getRowKey = useCallback((record: T) => String(record.id), [])

  const columns = useMemo<ColumnsType<T>>(
    () => [
      {
        dataIndex: 'name',
        key: 'name',
        title: t('memberExport.sectionTable.name'),
        width: 504,
        render: (_, record) => (
          <ExportRecordSummary
            memberId={memberId}
            record={record}
            dataSource={dataSource}
          />
        ),
      },
      {
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'right',
        title: t('memberExport.sectionTable.created'),
        width: 264,
        render: (value: string) => (
          <Typography.Text>
            {formatDocumentDate({ t, date: value })}
          </Typography.Text>
        ),
      },
    ],
    [dataSource, memberId, t],
  )

  const rowSelection = useMemo<TableRowSelection<T>>(
    () => ({
      type: 'checkbox',
      preserveSelectedRowKeys: true,
      selectedRowKeys:
        selectedExportRecords[memberId]?.[identifier]?.map(({ id }) =>
          String(id),
        ) || [],
      getCheckboxProps: (record) => ({
        name: String(record.id),
      }),
      onChange: (_selectedRowKeys, selectedRows) =>
        onSelectionChange(selectedRows),
    }),
    [identifier, memberId, onSelectionChange, selectedExportRecords],
  )

  return (
    <StyledCollapse
      bordered={false}
      defaultActiveKey={defaultOpen ? identifier : ''}
    >
      <StyledCollapsePanel
        header={t(`memberExport.sectionTable.${identifier}`, {
          defaultValue: identifier,
          count: pagination.total ?? dataSource.length,
        })}
        key={identifier}
      >
        <StyledTableWrapper>
          <StyledTable
            dataSource={dataSource}
            loading={loading}
            rowKey={getRowKey}
            columns={columns}
            rowSelection={rowSelection}
            pagination={pagination}
            onChange={onChange}
          />
        </StyledTableWrapper>
      </StyledCollapsePanel>
    </StyledCollapse>
  )
}

const StyledTable = styled(Table)`
  &&& .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 5px;
  }

  .ant-table-thead th.ant-table-column-sort,
  td.ant-table-column-sort {
    background: none;
  }

  .ant-table-tbody > tr:nth-child(odd) {
    background-color: ${colors.background};
  }

  .ant-table-tbody > tr:nth-child(even) {
    background: none;
  }
` as typeof Table

const StyledTableWrapper = styled.div`
  position: relative;

  .ant-pagination {
    position: sticky;
    bottom: ${FOOTER_HEIGHT}px;
    background-color: ${colors.surface};
    padding: 16px 0;
  }
`

const StyledCollapse = styled(Collapse)`
  background: none;
`

const StyledCollapsePanel = styled(Collapse.Panel)`
  &&& .ant-collapse-content {
    box-shadow: 0px 4px 12px 0px #00000026;
  }

  &&& .ant-collapse-header {
    align-items: baseline;
    position: sticky;
    top: 0;
    background-color: ${colors.surface};
    padding: 16px 8px;
    z-index: 2;
    border-bottom: 1px solid ${colors.lightBorder};
  }

  &&& .ant-collapse-header-text {
    font-weight: 600;
  }
`
