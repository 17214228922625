import { useCallback } from 'react'

import { PicLeftOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { TemplateSelect } from 'app/components/tasks/template-select'
import { useGetTaskTemplatesQuery } from 'app/redux/api/emergency-room/tasks'

const StyledButton = styled(Button)``

interface DescriptionTemplateSelectProps {
  onChange?: (value: string) => void
  value?: string
  disabled?: boolean
}

export const DescriptionTemplateSelect = ({
  onChange,
  value,
  disabled,
}: DescriptionTemplateSelectProps) => {
  const { t } = useTranslation()

  const {
    data: templates = [],
    isLoading: isTemplatesLoading,
    isError: isTemplatesError,
  } = useGetTaskTemplatesQuery()

  const handleSelectTemplate = useCallback(
    (templateId?: string) => {
      const { description } =
        templates?.find((template) => template?.id === templateId) || {}

      if (!onChange || !description) return
      onChange(`${value ? `${value}\n\n` : ''}${description}`)
    },
    [onChange, templates, value],
  )

  return (
    <TemplateSelect
      templates={templates}
      loading={isTemplatesLoading}
      hasError={isTemplatesError}
      onSelect={handleSelectTemplate}
    >
      {/* requires span to preserve style (https://github.com/ant-design/ant-design/issues/27340) */}
      <span>
        <StyledButton
          icon={<PicLeftOutlined />}
          type="link"
          data-testid="task-template-button"
          disabled={disabled}
          data-dd-privacy="allow"
          data-dd-action-name="task-form-description-template-picker:open"
        >
          {t('tasks.templates.label')}
        </StyledButton>
      </span>
    </TemplateSelect>
  )
}
