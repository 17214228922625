import { type ReactNode, useEffect } from 'react'

import type { Pharmacy } from '@dialogue/services/dist/emerald/v2/model'
import { Alert, Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import type { CSSProp } from 'styled-components'

import { AddressDisplay } from 'app/components/address-display'
import { MemberInfoCard } from 'app/containers/member-profile-page/member-info-card'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  pharmaciesActions,
  selectPharmacyError,
  selectPharmacyFetching,
  selectEmeraldPreferredPharmacy,
  selectPreferredPharmacy,
} from 'app/redux/medical-profile/pharmacies'

export const usePreferredPharmacy = (patientId: string | number) => {
  const emeraldPharmacy = useAppSelector((state) =>
    selectEmeraldPreferredPharmacy(state, patientId),
  )
  const pharmacy = useAppSelector((state) =>
    selectPreferredPharmacy(state, patientId),
  )
  const fetching = useAppSelector(selectPharmacyFetching)
  const error = useAppSelector(selectPharmacyError)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (patientId) {
      dispatch(
        pharmaciesActions.getPreferred({
          patientId: patientId?.toString(),
        }),
      )
    }
  }, [dispatch, patientId])

  // If Emerald pharmacy exists, use that first
  if (emeraldPharmacy) {
    return { pharmacy: emeraldPharmacy, fetching, error }
  }

  // pharmacy from Emerald doesn't exist, use ER data if it exists
  if (pharmacy) {
    // Map ER data to Emerald model
    const mappedPharmacy: Pharmacy = {
      city: pharmacy.locality,
      fax: pharmacy.fax_number,
      id: pharmacy.id.toString(),
      name: pharmacy.name,
      phone: pharmacy.phone_number,
      postal_code: pharmacy.postal_code,
      province: pharmacy.admin_area_level_1_iso_code,
      street_address1: pharmacy.street_number,
      street_address2: pharmacy.street_number2 ?? null,
      // following properties are required by Emerald model but don't exist in ER:
      created_at: '',
      updated_at: '',
      country: null,
      phone_ext: null,
    }

    return {
      pharmacy: mappedPharmacy,
      type: pharmacy.pharmacy_type,
      fetching,
      error,
    }
  }

  return { pharmacy: null, fetching, error }
}

export const PreferredPharmacy = ({
  patientId,
  title,
  titleIcon,
  styles,
}: {
  patientId: string | number
  title?: string
  titleIcon?: ReactNode
  styles?: CSSProp
}) => {
  const { t } = useTranslation()
  const { pharmacy, type, fetching, error } = usePreferredPharmacy(patientId)
  let content: React.ReactNode = null

  if (error) {
    content = (
      <Alert
        showIcon
        message={t('medicalProfile.preferredPharmacy.error')}
        type="error"
      />
    )
  } else if (pharmacy === null) {
    content = (
      <Empty
        imageStyle={{ height: '50px', margin: 0 }}
        description={t('medicalProfile.preferredPharmacy.empty')}
      ></Empty>
    )
  } else if (pharmacy) {
    content = (
      <AddressDisplay
        city={pharmacy.city}
        country={pharmacy.country}
        fax={pharmacy.fax}
        name={pharmacy.name}
        phone={pharmacy.phone}
        phone_ext={pharmacy.phone_ext}
        postal_code={pharmacy.postal_code}
        province={pharmacy.province}
        street_address1={pharmacy.street_address1}
        street_address2={pharmacy.street_address2}
        pharmacy_type={type}
      />
    )
  }

  return (
    <MemberInfoCard
      loading={fetching}
      titleIcon={titleIcon}
      title={title}
      content={content}
      updatedAt={pharmacy?.updated_at}
      css={styles}
    />
  )
}
