// @ts-strict-ignore
import type {
  Allergies,
  Medications,
  MedicalHistory,
  MedicalProfileProperties,
} from '@dialogue/services/dist/er/model'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

export interface State extends MedicalProfileProperties {
  fetching: boolean
  error: Error | null
}

type PatientAction<T> = PayloadAction<{ patientId: string | number } & T>

export type MemberMedicalProfileState = Record<string, State>

const INITIAL_STATE: MemberMedicalProfileState = {}

const defaultPatientState: State = {
  fetching: false,
  error: null,
  allergies: null,
  medications: null,
  medical_history: null,
}

const getPatientState = (
  state: MemberMedicalProfileState,
  patientId: string | number,
) => (state[patientId] = state[patientId] ?? { ...defaultPatientState })

export const { actions: memberMedicalProfileActions, reducer } = createSlice({
  name: '@@medicalProfile/memberMedicalProfile',
  initialState: INITIAL_STATE,
  reducers: {
    get(state, { payload: { patientId } }: PatientAction<{}>) {
      const patientState = getPatientState(state, patientId)
      patientState.fetching = true
      patientState.error = null
    },
    updateAllergies(
      state,
      {
        payload,
      }: PayloadAction<{
        patientId: string | number
        allergies: string
      }>,
    ) {
      const patientState = getPatientState(state, payload.patientId)
      patientState.fetching = true
      patientState.error = null
    },
    updateMedications(
      state,
      {
        payload,
      }: PayloadAction<{
        patientId: string | number
        medications: string
      }>,
    ) {
      const patientState = getPatientState(state, payload.patientId)
      patientState.fetching = true
      patientState.error = null
    },
    updateMedicalHistory(
      state,
      {
        payload,
      }: PayloadAction<{
        patientId: string | number
        medical_history: string
      }>,
    ) {
      const patientState = getPatientState(state, payload.patientId)
      patientState.fetching = true
      patientState.error = null
    },
    allergiesReceived(
      state,
      { payload: { patientId, allergies } }: PatientAction<Allergies>,
    ) {
      const patientState = getPatientState(state, patientId)
      patientState.fetching = false
      patientState.allergies = allergies
    },
    medicationsReceived(
      state,
      { payload: { patientId, medications } }: PatientAction<Medications>,
    ) {
      const patientState = getPatientState(state, patientId)
      patientState.fetching = false
      patientState.medications = medications
    },
    medicalHistoryReceived(
      state,
      {
        payload: { patientId, medical_history },
      }: PatientAction<MedicalHistory>,
    ) {
      const patientState = getPatientState(state, patientId)
      patientState.fetching = false
      patientState.medical_history = medical_history
    },
    received(
      state,
      {
        payload: { patientId, allergies, medications, medical_history },
      }: PatientAction<MedicalProfileProperties>,
    ) {
      const patientState = getPatientState(state, patientId)
      patientState.fetching = false
      patientState.allergies = allergies
      patientState.medications = medications
      patientState.medical_history = medical_history
    },
    failed(
      state,
      { payload: { error, patientId } }: PatientAction<{ error: Error }>,
    ) {
      const patientState = getPatientState(state, patientId)
      patientState.fetching = false
      patientState.error = error
    },
  },
})

export default reducer

export const selectMemberMedicalProfile = (
  state: ReduxState,
  patientId: string | number,
) => state.medicalProfile.memberMedicalProfile[patientId]
