import { createAction, createReducer } from '@reduxjs/toolkit'

import type {
  AuthentificationState,
  EnrichedPractitionerProfile,
} from './types'

export const initialState: AuthentificationState = {
  accessToken: null,
  profile: null,
  isAuthenticated: false,
  error: null,
  redirectUrl: null,
  isRenewing: false,
  userRoles: null,
  userPermissions: null,
}

export const renewSession = createAction('@@authentification/renewSession')

export const login = createAction('@@authentification/login')

export const loginSuccess = createAction<{
  accessToken: string
  userRoles: string[]
  userPermissions: string[]
}>('@@authentification/loginSuccess')

export const loginProfileSuccess = createAction<EnrichedPractitionerProfile>(
  '@@authentification/loginProfileSuccess',
)

export const logout = createAction('@@authentification/logout')

export const logoutSuccess = createAction('@@authentification/logoutSuccess')

export const loginFailure = createAction<Error>(
  '@@authentification/loginFailure',
)

export const updateRedirectUrl = createAction<string>(
  '@@authentification/updateRedirectUrl',
)

export const initAuthFailed = createAction('@@authentification/initAuthFailed')

export default createReducer(initialState, (builder) => {
  builder.addCase(loginSuccess, (state, action) => {
    state.accessToken = action.payload.accessToken
    state.userRoles = action.payload.userRoles
    state.userPermissions = action.payload.userPermissions
    state.isAuthenticated = true
    state.error = null
  })
  builder.addCase(loginProfileSuccess, (state, action) => {
    state.profile = action.payload
    state.isRenewing = false
  })
  builder.addCase(logoutSuccess, () => ({ ...initialState, isRenewing: false }))
  builder.addCase(loginFailure, (state, action) => {
    state.error = action.payload
    state.isRenewing = false
  })
  builder.addCase(updateRedirectUrl, (state, action) => {
    state.redirectUrl = action.payload
  })
  builder.addCase(renewSession, (state, _action) => {
    state.isRenewing = true
  })
  builder.addCase(initAuthFailed, (state, _action) => {
    state.isRenewing = false
  })
})
