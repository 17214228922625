import type { Action } from 'redux'
import type { TakeableChannel, Task } from 'redux-saga'
import {
  type ActionPattern,
  call,
  cancel,
  fork,
  type ForkEffect,
  type HelperWorkerParameters,
  take,
} from 'redux-saga/effects'

interface TakeKeyedLatest {
  <A extends Action>(
    pattern: ActionPattern<A>,
    keySelector: (action: A) => string | number | null,
    worker: (action: A) => any,
  ): ForkEffect<never>
  <A extends Action, Fn extends (...args: any[]) => any>(
    pattern: ActionPattern<A>,
    keySelector: (action: A) => string | number | null,
    worker: Fn,
    ...args: HelperWorkerParameters<A, Fn>
  ): ForkEffect<never>
  <T>(
    pattern: TakeableChannel<T>,
    keySelector: (item: T) => string | number | null,
    worker: (item: T) => any,
  ): ForkEffect<never>
  <T, Fn extends (...args: any[]) => any>(
    pattern: TakeableChannel<T>,
    keySelector: (item: T) => string | number | null,
    worker: Fn,
    ...args: HelperWorkerParameters<T, Fn>
  ): ForkEffect<never>
}

/**
 * Utility method which replicates takeLatest, allowing to specify an additional selector.
 * Tasks matching provided pattern and which yield the same selector value will cancel each other,
 * while tasks with different keys will be independently run.
 *
 * Example:
 * ```typescript
 * yield takeKeyedLatest(
 *   Types.GET_USER,
 *   action => action.userId,
 *   function*() {}
 * )
 * // action1 = { type: Types.GET_USER, userId: 123 }
 * // fork new task T1
 * // action2 = { type: Types.GET_USER, userId: 456 }
 * // fork new task T2
 * // action3 = { type: Types.GET_USER, userId: 123 }
 * // cancel T1, fork new task T3
 * ```
 *
 * Inspired by https://redux-saga.js.org/docs/api/#takelatestpattern-saga-args
 */
export const takeKeyedLatest: TakeKeyedLatest = <T>(
  pattern: any,
  keySelector: (action: T) => string | number | null,
  worker: (...args: any[]) => any,
  ...args: any[]
) =>
  fork(function* () {
    const taskMap: Record<string | number, Task> = {}

    while (true) {
      const action: T = yield take(pattern)
      const taskKey: string | number | null = yield call(keySelector, action)

      if (taskKey === null || taskKey === undefined) {
        // don't run when taskKey doesn't return anything
        continue
      }

      if (taskKey in taskMap) {
        yield cancel(taskMap[taskKey])
      }

      taskMap[taskKey] = yield fork(worker as any, ...args.concat(action))
    }
  })
