import {
  createContext,
  useCallback,
  useEffect,
  useState,
  type Context,
  type ReactNode,
} from 'react'

import type { NewTask, TaskWithLinkedTasks } from '@dialogue/coredata'

import { useAppSelector } from 'app/hooks'
import {
  useGetTaskQuery,
  useGetUnreadCommentCountQuery,
} from 'app/redux/api/emergency-room/tasks'
import { selectUserId } from 'app/redux/authentification/selectors'
import { useSearchParamState } from 'app/routing/utils'
import { track } from 'app/services/snowplow-analytics'

type ContextValues = {
  task?: TaskWithLinkedTasks
  parentTask?: TaskWithLinkedTasks
  setDrawerTask: (taskId: string | null) => void
  initialData?: Partial<NewTask>
  setDrawerInitialData: (data: Partial<NewTask>) => void
  unreadSubCount: number
  isLoading: boolean
  isOpen: boolean
  closeDrawer: () => void
}

// TODO: improve this using the same pattern as the WebViewerContext
// (making the context nullable, but creating a hook to safely access it as non-null)
export const placeholderContext: ContextValues = {
  task: undefined,
  parentTask: undefined,
  setDrawerTask: (_taskId: string | null) => {},
  initialData: undefined,
  setDrawerInitialData: (_data: Partial<NewTask>) => {},
  unreadSubCount: 0,
  isLoading: false,
  isOpen: false,
  closeDrawer: () => {},
}

export const TaskDrawerContext: Context<ContextValues> =
  createContext(placeholderContext)

export const TASK_DRAWER_ID = 'taskDrawer'
export const NEW_TASK_DRAWER_ID = 'newTaskDrawer'

const TASK_ID_SEARCH_PARAM = 'taskId'

export const TaskDrawerProvider = ({ children }: { children: ReactNode }) => {
  const [taskId, setTaskId] = useSearchParamState(TASK_ID_SEARCH_PARAM)
  const [initialData, setInitialData] = useState<Partial<NewTask> | undefined>()

  const { currentData: task, isLoading: isLoadingTask } = useGetTaskQuery(
    { taskId: taskId || '' },
    { skip: !taskId },
  )

  const { currentData: parentTask, isLoading: isLoadingParent } =
    useGetTaskQuery(
      { taskId: task?.parent_id || initialData?.parent_id || '' },
      { skip: !task?.parent_id && !initialData?.parent_id },
    )

  const { data: unreadSubCount = 0 } = useGetUnreadCommentCountQuery(
    {},
    { pollingInterval: 10000 },
  )

  const currentUserId = useAppSelector((state) => selectUserId(state))

  useEffect(() => {
    if (!task) return
    track(
      'opened_task_details_view',
      {
        practitioner_id: currentUserId,
        task_id: task.id,
        task_tenant_id: task.tenant_id,
        task_assignee_id: task.assignee_id,
        task_teams: task.teams,
      },
      '1-0-1',
    )
  }, [currentUserId, task])

  const setDrawerTask = useCallback(
    (taskId: string | null) => {
      setTaskId(taskId)
      setInitialData(undefined)
    },
    [setTaskId],
  )

  const setDrawerInitialData = useCallback(
    (data: Partial<NewTask>) => {
      setInitialData(data)
      setTaskId(null)
    },
    [setTaskId],
  )
  const handleCloseDrawer = useCallback(() => {
    setInitialData(undefined)
    setTaskId(null)
  }, [setTaskId])

  useEffect(() => {
    /*
      Clear initialData when going back to a existing task drawer
    */
    if (taskId) setInitialData(undefined)
  }, [taskId])

  return (
    <TaskDrawerContext.Provider
      value={{
        task,
        parentTask,
        initialData,
        setDrawerTask,
        setDrawerInitialData,
        unreadSubCount,
        isOpen: !!initialData || !!taskId,
        isLoading: isLoadingParent || isLoadingTask,
        closeDrawer: handleCloseDrawer,
      }}
    >
      {children}
    </TaskDrawerContext.Provider>
  )
}
