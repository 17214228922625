import type { MemberDocument } from '@dialogue/document-center'
import type { Note } from '@dialogue/notepad-client'

import type { SharedExportProperties } from './types'

export const FOOTER_HEIGHT = 72

export const isMemberDocument = (
  record: SharedExportProperties,
): record is MemberDocument => {
  return 'member_id' in record
}

export const isNote = (record: SharedExportProperties): record is Note => {
  return 'template_id' in record
}
