// @ts-strict-ignore
import { datadogRum } from '@datadog/browser-rum'
import { setUserId } from '@snowplow/browser-tracker'
import jwt_decode from 'jwt-decode'
import { all, call, put, select, takeLatest } from 'typed-redux-saga/macro'

import {
  initAuthFailed,
  login,
  loginFailure,
  loginProfileSuccess,
  loginSuccess,
  logout,
  logoutSuccess,
  renewSession,
} from 'app/redux/authentification'
import { selectIsAuthenticated } from 'app/redux/authentification/selectors'
import type {
  Auth0Claims,
  EnrichedPractitionerProfile,
} from 'app/redux/authentification/types'
import deviceInfo from 'app/services/device-info'
import { ldclient } from 'app/services/feature-flags'
import routes from 'app/services/routes'
import { Sprig } from 'app/services/sprig'

import { ElectronAuthClient, WebAuthClient } from '../services/auth-client'

import { initEmergencyRoomClient, poll } from './utils'

// interval with which authentication is checked
const AUTHENTICATION_WATCH_INTERVAL_MS = 5 * 60_000
const ME: 'me' = 'me'

export const authClient = deviceInfo.isElectron()
  ? new ElectronAuthClient()
  : new WebAuthClient()

export function getUserRoles(token: string): string[] {
  const roles = getAuthTokenClaim(token, 'roles')
  return Array.isArray(roles) ? roles : [roles]
}

function getAuthTokenClaim(token: string, claimName: string) {
  try {
    const claims: Auth0Claims = jwt_decode(token)
    return claims[`https://dialogue/claims/${claimName}`]
  } catch (e) {
    throw new Error('Invalid access token')
  }
}

export function getAuthTokenPermissions(token: string): string[] {
  try {
    const claims: Auth0Claims = jwt_decode(token)
    return [...claims.permissions, ...(claims.scope || '').split(' ')]
  } catch (e) {
    throw new Error('Invalid access token')
  }
}

export function* watchAuthentication() {
  const isAuth = yield* select(selectIsAuthenticated)
  if (isAuth) {
    try {
      yield* call(renewAuth)
    } catch (e) {
      console.warn('auth failed', e)
      if (e.statusCode === 403) {
        yield* call(doLogout)
      }
    }
  }
}

export function* loadProfile() {
  const er = yield* call(initEmergencyRoomClient)
  const profile = yield* call(er.getPractitioner, ME)

  const enrichedProfile: EnrichedPractitionerProfile = {
    ...profile,
    userName: `${profile.givenName} ${profile.familyName}`,
  }

  return enrichedProfile
}

function* cypressRenewAuth() {
  const cypressToken = localStorage.getItem('cypress_access_token')

  const userRoles = yield* call(getUserRoles, cypressToken)
  const userPermissions = yield* call(getAuthTokenPermissions, cypressToken)

  if (cypressToken !== null) {
    yield* put(
      loginSuccess({ accessToken: cypressToken, userRoles, userPermissions }),
    )
  } else {
    throw new Error('not auth')
  }
}

export function* renewAuth() {
  yield* put(renewSession())

  if (window.Cypress) {
    yield* call(cypressRenewAuth)
  } else {
    const { accessToken } = yield* call(authClient.renewAuth)
    const userRoles = yield* call(getUserRoles, accessToken)
    const userPermissions = yield* call(getAuthTokenPermissions, accessToken)

    yield* put(loginSuccess({ accessToken, userRoles, userPermissions }))
  }
}

export function* doLogin() {
  try {
    const { accessToken } = yield* call(authClient.authorize)
    const userRoles = yield* call(getUserRoles, accessToken)
    const userPermissions = yield* call(getAuthTokenPermissions, accessToken)
    yield* put(loginSuccess({ accessToken, userRoles, userPermissions }))
  } catch (e) {
    console.warn(e)
    yield* put(loginFailure(e))
  }
}

export function* doLogout() {
  localStorage.clear()

  yield* call(authClient.logout)

  yield* put(logoutSuccess())

  if (Sprig) {
    yield* call(Sprig.logoutUser)
  }

  window.location.hash = '#' + routes.login()
}

export function* onLoginSuccess() {
  const profile = yield* call(loadProfile)

  yield* call(identifyUser, profile)
  yield* put(loginProfileSuccess(profile))
}

export function* initAuth() {
  try {
    yield* call(renewAuth)
  } catch (e) {
    console.log(e)

    yield* put(initAuthFailed())
    window.location.hash = '#' + (yield* call(routes.login))
  }
}

export function identifyUser(profile: EnrichedPractitionerProfile) {
  const id = profile.id.toString()

  // Datadog RUM
  datadogRum.setUser({
    id,
    email: profile.email,
    specialization: profile.mainSpecialization,
  })

  // snowplow
  setUserId(id)

  // Launchdarkly
  ldclient
    .identify({
      key: id,
      email: profile.email,
      avatar: profile.picture,
      firstName: profile.givenName,
      lastName: profile.familyName,
    })
    .catch((e) => {
      console.warn('LaunchDarkly failed to identify user', e)
    })

  if (Sprig) {
    Sprig.setUserId(String(profile.id))
    Sprig.setEmail(profile.email)
    Sprig.setAttributes({
      mainSpecialization: profile.mainSpecialization,
      createdAt: profile.created,
    })
  } else {
    console.log('Sprig not initialized, skipping sprig user identification')
  }
}

export default function* authentificationSagas() {
  yield* all([
    call(initAuth),
    takeLatest(login, doLogin),
    takeLatest(logout, doLogout),
    takeLatest(loginSuccess, onLoginSuccess),
    poll(
      loginSuccess,
      logout,
      AUTHENTICATION_WATCH_INTERVAL_MS,
      watchAuthentication,
    ),
  ])
}
