import type { TwilioError } from '@twilio/voice-sdk'

export enum TelephoneActionTypes {
  CALL_ENDED = '@@telephone/CALL_ENDED',
  UPDATE_CALL_STATUS = '@@telephone/UPDATE_CALL_STATUS',
  UPDATE_CALL_ERROR = '@@telephone/UPDATE_CALL_ERROR',
  MAKE_CALL = '@@telephone/MAKE_CALL',
  TOGGLE_MUTE = '@@telephone/TOGGLE_MUTE',
  HANG_UP = '@@telephone/HANG_UP',
  ADD_WARNING = '@@telephone/ADD_WARNING',
  REMOVE_WARNING = '@@telephone/REMOVE_WARNING',
  DTMF_INPUT = '@@telephone/DTMF_INPUT',
  HIDE_WINDOW = '@@telephone/HIDE_WINDOW',
  SHOW_WINDOW = '@@telephone/SHOW_WINDOW',
  SET_NUMBER = '@@telephone/SET_NUMBER',
  REDIAL = '@@telephone/REDIAL',
  ADD_PARTICIPANT = '@@telephone/ADD_PARTICIPANT',
  PARTICIPANT_ADDED = '@@telephone/PARTICIPANT_ADDED',
  PARTICIPANT_JOINED = '@@telephone/PARTICIPANT_JOINED',
  PARTICIPANT_UPDATED = '@@telephone/PARTICIPANT_UPDATED',
  PARTICIPANT_LEFT = '@@telephone/PARTICIPANT_LEFT',
  USER_JOINED = '@@telephone/USER_JOINED',
}

export enum CallStatus {
  AUTHENTICATED = 'authenticated',
  INITIALIZED = 'initialized',
  DIALING = 'dialing',
  RINGING = 'ringing',
  CONNECTED = 'connected',
  CONNECTION_ERROR = 'connection error',
  CALL_ENDED = 'call ended',
  ERROR = 'error',
}

// We'll add more values as we extend features
export enum CallParticipantStatus {
  CONNECTED = 'connected',
  DIALING = 'dialing',
  DISCONNECTED = 'disconnected',
}

export interface CallParticipant {
  callSid: string | null
  conferenceSid: string
  isMuted: boolean
  isOnHold: boolean
  phoneNumber: string
  status: CallParticipantStatus
}

export interface TelephoneState {
  episodeId: string | null
  phoneNumber: string | null
  status: CallStatus | null
  error: TwilioError.TwilioError | null
  warnings: string[]
  windowVisible: boolean
  hungUp: boolean
  muted: boolean
  participants: CallParticipant[]
}
