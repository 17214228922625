import config from './'

// Scheduling
export const TK_ENABLED = config.TIMEKEEPER_URL !== null
export const EMERALD_ENABLED = config.EMERALD_API_URL !== null
export const SCHEDULING_ENABLED = TK_ENABLED || EMERALD_ENABLED

// Profile Menu
export const SUBMIT_RESOURCE_ENABLED =
  config.PROFILE_MENU_SUBMIT_RESOURCE !== null
export const REPORT_INCIDENT_ENABLED =
  config.PROFILE_MENU_REPORT_INCIDENT !== null
export const CARE_TEAM_NEWSLETTER_ENABLED =
  config.PROFILE_MENU_CARE_TEAM_NEWSLETTER !== null
export const LUNCH_SCHEDULE_ENABLED =
  config.PROFILE_MENUS_LUNCH_SCHEDULE !== null
export const WFM_REQUEST_ENABLED = config.PROFILE_MENU_WFM_REQUEST !== null
