// @ts-strict-ignore
import { createSelector } from '@reduxjs/toolkit'
import { produce } from 'immer'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'

import type { ReduxState } from 'app/redux'
import { selectUserId } from 'app/redux/authentification/selectors'

import type * as actions from './actions'
import { type UserManagementState, UserManagementTypes } from './types'

export const INITIAL_STATE: UserManagementState = {
  practitioners: {},
  specializations: [],
  fetchingSpecializations: false,
}

export type UserManagementAction = ActionType<typeof actions>

export const reducer: Reducer<typeof INITIAL_STATE, UserManagementAction> = (
  state = INITIAL_STATE,
  action,
) =>
  produce(state, (draft) => {
    let practitionerId
    switch (action.type) {
      case UserManagementTypes.ADD_OR_UPDATE_PRACTITIONER:
        practitionerId = action.payload.practitionerId
        draft.practitioners[practitionerId].updating = true
        draft.practitioners[practitionerId].errorUpdating = null
        break
      case UserManagementTypes.ADD_OR_UPDATE_PRACTITIONER_SUCCESS:
        practitionerId = action.payload.practitionerId
        draft.practitioners[practitionerId].updating = false
        draft.practitioners[practitionerId].profile =
          action.payload.practitionerProfile
        break
      case UserManagementTypes.ADD_OR_UPDATE_PRACTITIONER_FAILURE:
        practitionerId = action.payload.practitionerId
        draft.practitioners[practitionerId].updating = false
        draft.practitioners[practitionerId].errorUpdating = action.error
        break

      case UserManagementTypes.GET_PRACTITIONER:
        practitionerId = action.payload.practitionerId
        draft.practitioners[practitionerId] =
          draft.practitioners[practitionerId] || {}
        draft.practitioners[practitionerId].fetching = true
        draft.practitioners[practitionerId].errorFetching = null
        break
      case UserManagementTypes.GET_PRACTITIONER_SUCCESS:
        practitionerId = action.payload.practitionerId
        draft.practitioners[practitionerId] =
          draft.practitioners[practitionerId] || {}
        draft.practitioners[practitionerId].fetching = false
        draft.practitioners[practitionerId].profile =
          action.payload.practitionerProfile
        break
      case UserManagementTypes.GET_PRACTITIONER_FAILURE:
        practitionerId = action.payload.practitionerId
        draft.practitioners[practitionerId] =
          draft.practitioners[practitionerId] || {}
        draft.practitioners[practitionerId].fetching = false
        draft.practitioners[practitionerId].errorFetching = action.error
        break

      case UserManagementTypes.GET_LICENSES:
        practitionerId = action.payload.practitionerId
        draft.practitioners[practitionerId] =
          draft.practitioners[practitionerId] || {}
        draft.practitioners[practitionerId].fetchingLicenses = true
        draft.practitioners[practitionerId].licensesError = null
        break
      case UserManagementTypes.GET_LICENSES_SUCCESS:
        practitionerId = action.payload.practitionerId
        draft.practitioners[practitionerId].fetchingLicenses = false
        // maps [{id: 1}] -> {1: {id: 1}}
        draft.practitioners[practitionerId].licenses =
          action.payload.licenses.reduce(
            (obj, next) => ({ ...obj, [next.id]: next }),
            {},
          )
        break
      case UserManagementTypes.GET_LICENSES_FAILURE:
        practitionerId = action.payload.practitionerId
        draft.practitioners[practitionerId].fetchingLicenses = false
        draft.practitioners[practitionerId].licensesError = action.error
        break

      case UserManagementTypes.CREATE_LICENSE:
      case UserManagementTypes.UPDATE_LICENSE:
      case UserManagementTypes.DELETE_LICENSE:
        practitionerId = action.payload.practitionerId
        draft.practitioners[practitionerId].requestLicense = true
        draft.practitioners[practitionerId].licenseError = null
        break
      case UserManagementTypes.CRUD_LICENSE_SUCCESS: {
        practitionerId = action.payload.practitionerId
        const { license, licenseId } = action.payload
        // create if empty
        draft.practitioners[practitionerId].licenses =
          draft.practitioners[practitionerId].licenses ?? {}
        // no license -> delete operation, license -> create/read/update
        if (!license) {
          delete draft.practitioners[practitionerId].licenses[licenseId]
        } else {
          draft.practitioners[practitionerId].licenses[licenseId] = license
        }
        draft.practitioners[practitionerId].requestLicense = false
        break
      }
      case UserManagementTypes.CRUD_LICENSE_FAILURE:
        practitionerId = action.payload.practitionerId
        draft.practitioners[practitionerId].requestLicense = false
        draft.practitioners[practitionerId].licenseError = action.error
        break
      case UserManagementTypes.GET_SPECIALIZATIONS:
        draft.fetchingSpecializations = true
        break
      case UserManagementTypes.GET_SPECIALIZATIONS_SUCCESS:
        draft.specializations = action.payload.specializations
        draft.fetchingSpecializations = false
        break
      case UserManagementTypes.GET_SPECIALIZATIONS_FAILURE:
        draft.fetchSpecializationsError = action.payload.error
        draft.fetchingSpecializations = false
        break
      case UserManagementTypes.GET_EMR_ID:
        practitionerId = action.payload.practitionerId
        draft.practitioners[practitionerId] =
          draft.practitioners[practitionerId] || {}
        draft.practitioners[practitionerId].fetchingEmrId = true
        draft.practitioners[practitionerId].errorFetchingEmrId = null
        break
      case UserManagementTypes.GET_EMR_ID_SUCCESS:
        practitionerId = action.payload.practitionerId
        draft.practitioners[practitionerId] =
          draft.practitioners[practitionerId] || {}
        draft.practitioners[action.payload.practitionerId].emrId =
          action.payload.emrId
        draft.practitioners[action.payload.practitionerId].fetchingEmrId = false
        draft.practitioners[action.payload.practitionerId].errorFetchingEmrId =
          null
        break
      case UserManagementTypes.GET_EMR_ID_FAILURE:
        practitionerId = action.payload.practitionerId
        draft.practitioners[practitionerId] =
          draft.practitioners[practitionerId] || {}
        draft.practitioners[practitionerId].fetchingEmrId = false
        draft.practitioners[practitionerId].errorFetchingEmrId = action.error
        break
    }
  })

export default reducer

export const selectUserManagementUser = (
  state: ReduxState,
  practitionerId: string,
) => state.userManagement.practitioners[practitionerId]

export const selectPractitionerLicensesFetching = createSelector(
  selectUserManagementUser,
  (practitioner) => practitioner?.fetchingLicenses,
)

export const selectPractitionerLicensesError = createSelector(
  selectUserManagementUser,
  (practitioner) => practitioner?.licensesError,
)

export const selectPractitionerLicenses = (
  state: ReduxState,
  practitionerId: string,
) => selectUserManagementUser(state, practitionerId)?.licenses

export const selectOwnPractitionerLicenses = (state: ReduxState) => {
  const practitionerId = selectUserId(state)
  return selectPractitionerLicenses(state, String(practitionerId))
}

export const selectOnePractitionerLicense = (
  state: ReduxState,
  licenseId?: string,
) => {
  const licenses = selectOwnPractitionerLicenses(state) || {}

  if (licenseId) {
    return licenses[licenseId]
  }

  const licensesArray = Object.values(licenses)
  return licensesArray.length === 1 ? licensesArray[0] : undefined
}

export const selectEmrId = (state: ReduxState, practitionerId: string) =>
  selectUserManagementUser(state, practitionerId)?.emrId

export const selectFetchingEmrId = (
  state: ReduxState,
  practitionerId: string,
) => selectUserManagementUser(state, practitionerId)?.fetchingEmrId

export const selectErrorFetchingEmrId = (
  state: ReduxState,
  practitionerId: string,
) => selectUserManagementUser(state, practitionerId)?.errorFetchingEmrId
