import { useCallback, useContext, useMemo, useRef } from 'react'

import {
  TaskStatus,
  type ItemTask,
  type NewTask,
  type TaskWithLinkedTasks,
  type UpdateTask,
} from '@dialogue/coredata'
import { Col, ConfigProvider, Row, Tabs, type TabsProps } from 'antd'
import type { ConfigProviderProps } from 'antd/lib/config-provider'
import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  NextStepsForm,
  usePersistedNextSteps,
  type NextStepsRefType,
} from 'app/components/tasks/next-steps-form'
import { TaskSection } from 'app/components/tasks/task-section'
import { Comments } from 'app/containers/tasks/comments'
import { SubtasksList } from 'app/containers/tasks/subtasks-list'
import { TaskForm } from 'app/containers/tasks/task-form'
import { useVariation } from 'app/hooks'
import { Flags } from 'app/services/feature-flags'
import { colors } from 'app/theme'

import { Activities } from './activities'
import {
  TaskAttachmentsForm,
  TaskAttachmentsStandalone,
} from './task-attachments-form'
import { TaskBackButton } from './task-back-button'
import { TaskDrawerContext } from './task-drawer-context'
import { TaskDrawerFooter } from './task-drawer-footer'
import { TaskDrawerHeader } from './task-drawer-header'

const TaskDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
const TaskDetailsBody = styled.div`
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 16px;
`

const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const StyledRow = styled(Row)`
  height: 100%;
`

const DetailsCol = styled(Col)`
  border-right: 1px solid ${colors.lightBorder};
  height: 100%;
`

const SidebarCol = styled(Col)`
  max-height: 100%;
  padding: 16px;
`

const DrawerHeader = styled(TaskDrawerHeader)`
  padding: 12px;
`

const DrawerFooter = styled(TaskDrawerFooter)`
  padding: 12px;
`

const StyledAttachmentsForm = styled(TaskAttachmentsForm)`
  height: 100%;
  max-height: 45%;
`

const StyledAttachmentsStandalone = styled(TaskAttachmentsStandalone)`
  height: 100%;
  max-height: 45%;
`

const flexContainerCss = `
  max-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const StyledTabs = styled(Tabs)`
  max-height: 55%;
  height: 100%;
  .ant-tabs-content-holder {
    ${flexContainerCss}
  }
  .ant-tabs-content.ant-tabs-content-top {
    ${flexContainerCss}
  }
  .ant-tabs-tabpane:not(.ant-tabs-tabpane-hidden) {
    ${flexContainerCss}
  }
`

const TaskSidepanelTabs = ({
  commentsTaskId,
  activitiesTaskId,
}: {
  commentsTaskId?: string
  activitiesTaskId?: string
}) => {
  const { t } = useTranslation()
  const items = useMemo<TabsProps['items']>(
    () => [
      {
        key: 'comments',
        label: <span data-dd-privacy="allow">{t('tasks.comments')}</span>,
        children: <Comments taskId={commentsTaskId} />,
      },
      {
        key: 'activities',
        label: <span data-dd-privacy="allow">{t('tasks.activities')}</span>,
        children: <Activities taskId={activitiesTaskId} />,
      },
    ],
    [activitiesTaskId, commentsTaskId, t],
  )
  return <StyledTabs defaultActiveKey="comments" items={items} />
}

type GetPopupContainer = NonNullable<ConfigProviderProps['getPopupContainer']>

const getPopupContainer: GetPopupContainer = (node) => {
  return node?.parentElement || window.document.body
}

interface NewTaskDrawerContentsProps {
  initialData: Partial<NewTask>
  onCreate: (values: NewTask) => Promise<void>
}

export const NewTaskDrawerContents = ({
  initialData,
  onCreate,
}: NewTaskDrawerContentsProps) => {
  const isSubtask = !!initialData.parent_id

  const { parentTask } = useContext(TaskDrawerContext)

  const sidebarTask = isSubtask ? parentTask : initialData

  const useStandAloneTaskAttachments = useVariation(
    Flags.taskAttachmentsV2,
    false,
  )

  const relatedDocumentIds = useMemo(() => {
    if (!isSubtask) return []

    return [
      ...(parentTask?.document_ids ?? []),
      ...(parentTask?.subtasks ?? []).flatMap(
        (subtask) => subtask.document_ids ?? [],
      ),
    ]
  }, [isSubtask, parentTask?.document_ids, parentTask?.subtasks])

  return (
    <ConfigProvider getPopupContainer={getPopupContainer}>
      <StyledRow>
        <DetailsCol span={16}>
          <TaskDetailsContainer>
            <DrawerHeader />
            <TaskDetailsBody>
              <TaskBackButton parentId={initialData.parent_id} />
              <TaskForm<NewTask>
                onFinish={onCreate}
                initialValues={{
                  ...initialData,
                  title: '',
                  owner: {
                    teams: initialData.team_ids,
                    assignee: initialData.assignee_id,
                  },
                  assignee_id: initialData.assignee_id
                    ? String(initialData.assignee_id)
                    : undefined,
                }}
                isSubtask={isSubtask}
              />
              {!isSubtask && (
                <SubtasksList task={initialData} createSubtaskDisabled />
              )}
            </TaskDetailsBody>
            <DrawerFooter />
          </TaskDetailsContainer>
        </DetailsCol>
        <SidebarCol span={8}>
          <SideBarContainer>
            {useStandAloneTaskAttachments && initialData?.member_id && (
              <StyledAttachmentsStandalone
                memberId={initialData.member_id}
                taskDocumentIds={initialData.document_ids ?? []}
                relatedDocumentIds={relatedDocumentIds}
              />
            )}
            {!useStandAloneTaskAttachments && (
              <StyledAttachmentsForm
                initialValues={{
                  id: parentTask?.id,
                  document_ids: sidebarTask?.document_ids,
                  member_id: sidebarTask?.member_id,
                }}
              />
            )}
            <TaskSidepanelTabs activitiesTaskId={parentTask?.id} />
          </SideBarContainer>
        </SidebarCol>
      </StyledRow>
    </ConfigProvider>
  )
}

const DEBOUNCE_TIME = 1000

interface TaskDrawerContentsProps {
  task: TaskWithLinkedTasks
  onUpdate: (taskId: string, values: UpdateTask) => Promise<ItemTask>
}

export const TaskDrawerContents = ({
  task,
  onUpdate,
}: TaskDrawerContentsProps) => {
  const { t } = useTranslation()
  const { parentTask } = useContext(TaskDrawerContext)

  const useStandAloneTaskAttachments = useVariation(
    Flags.taskAttachmentsV2,
    false,
  )

  const defaultNextStepsOwner = useMemo(() => {
    return {
      teams: task.parent_task?.teams?.map((team) => team.id),
      assignee: task.parent_task?.assignee_id,
    }
  }, [task.parent_task?.assignee_id, task.parent_task?.teams])

  const { nextSteps, setNextSteps, isLoadingNextSteps } = usePersistedNextSteps(
    `task-${task.id}`,
    defaultNextStepsOwner,
  )
  const nextStepsRef = useRef<NextStepsRefType>()
  const validateNextSteps = nextStepsRef.current?.validateNextSteps

  const isDisabled = task.status === TaskStatus.DONE
  const isSubtask = !!parentTask

  const sidebarTask = isSubtask ? parentTask : task

  const relatedDocumentIds = useMemo(() => {
    if (isSubtask) {
      return [
        ...(parentTask?.document_ids ?? []),
        // Ensure we don't include the current task's document_ids
        ...(parentTask.subtasks ?? [])
          .filter((subtask) => subtask.id !== task.id)
          .flatMap((subtask) => subtask.document_ids ?? []),
      ]
    }

    return (task.subtasks ?? []).flatMap(
      (subtask) => subtask.document_ids ?? [],
    )
  }, [
    isSubtask,
    parentTask?.document_ids,
    parentTask?.subtasks,
    task.id,
    task.subtasks,
  ])

  const handleTaskUpdate = useCallback(
    (values: UpdateTask) => {
      if (!task?.id) return
      onUpdate(task.id, values)
    },
    [task, onUpdate],
  )

  const handleValuesChange = useMemo(
    () => debounce(handleTaskUpdate, DEBOUNCE_TIME),
    [handleTaskUpdate],
  )

  return (
    <ConfigProvider getPopupContainer={getPopupContainer}>
      <StyledRow>
        <DetailsCol span={16}>
          <TaskDetailsContainer>
            <DrawerHeader />
            <TaskDetailsBody>
              <TaskBackButton parentId={task.parent_id} />
              <TaskForm<UpdateTask>
                disabled={isDisabled}
                onValuesChange={handleValuesChange}
                initialValues={{
                  ...task,
                  owner: {
                    teams: task.teams?.map((team) => team.id),
                    assignee: task.assignee_id,
                  },
                }}
                isSubtask={isSubtask}
              />
              {isSubtask && (
                <TaskSection title={t('tasks.nextSteps.title')}>
                  <NextStepsForm
                    ref={nextStepsRef}
                    disabled={isDisabled}
                    initialValues={nextSteps}
                    onChange={setNextSteps}
                    isLoading={isLoadingNextSteps}
                    taskId={task.id}
                  />
                </TaskSection>
              )}
              {!isSubtask && (
                <SubtasksList task={task} createSubtaskDisabled={isDisabled} />
              )}
            </TaskDetailsBody>
            <DrawerFooter
              validateNextSteps={validateNextSteps}
              nextSteps={nextSteps}
              isLoading={isLoadingNextSteps}
            />
          </TaskDetailsContainer>
        </DetailsCol>
        <SidebarCol span={8}>
          <SideBarContainer>
            {useStandAloneTaskAttachments && (
              <StyledAttachmentsStandalone
                memberId={task.member_id}
                taskDocumentIds={task.document_ids ?? []}
                relatedDocumentIds={relatedDocumentIds}
              />
            )}
            {!useStandAloneTaskAttachments && (
              <StyledAttachmentsForm
                disabled={isDisabled}
                initialValues={{
                  id: sidebarTask.id,
                  member_id: sidebarTask.member_id,
                  document_ids: sidebarTask?.document_ids || [],
                }}
              />
            )}
            <TaskSidepanelTabs
              activitiesTaskId={sidebarTask?.id}
              commentsTaskId={task.id}
            />
          </SideBarContainer>
        </SidebarCol>
      </StyledRow>
    </ConfigProvider>
  )
}
