import {
  /* eslint-disable-next-line @typescript-eslint/no-restricted-imports */
  useSelector,
  /* eslint-disable-next-line @typescript-eslint/no-restricted-imports */
  useDispatch,
  type TypedUseSelectorHook,
} from 'react-redux'

import type { AppDispatch, ReduxState } from 'app/redux'

export const useAppSelector: TypedUseSelectorHook<ReduxState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()
