import type {
  FaxTargetType,
  IncomingFaxDocument,
  MemberDocument,
  OutgoingFaxDocument,
  TemplateResponse,
} from '@dialogue/document-center'
import type { EmeraldTypes } from '@dialogue/services'
import { createSlice, isAnyOf, type PayloadAction } from '@reduxjs/toolkit'
import type { WritableDraft } from 'immer/dist/internal.js'

import config from 'app/config'
import type { ReduxState } from 'app/redux'

import { memberDocumentsActions } from './members'

export enum DocumentSource {
  INPUT_HEALTH = 'Input Health',
  DOCUMENT_CENTER = 'Document Center',
  INCOMING_FAX_DOCUMENT = 'Incoming Fax Document',
  OUTGOING_FAX_DOCUMENT = 'Temp Fax Document',
}

export enum DocumentKind {
  MEMBER_DOCUMENT = 'MEMBER_DOCUMENT',
  INCOMING_FAX_DOCUMENT = 'INCOMING_FAX_DOCUMENT',
  OUTGOING_FAX_DOCUMENT = 'OUTGOING_FAX_DOCUMENT',
  ANTICIPATED_OUTGOING_FAX = 'ANTICIPATED_OUTGOING_FAX',
  EXTERNAL_DOCUMENT = 'EXTERNAL_DOCUMENT',
}

export interface ViewerIHDocument extends EmeraldTypes.Document {
  url: string
  source: DocumentSource.INPUT_HEALTH
  member_id: number
  kind: DocumentKind.EXTERNAL_DOCUMENT
}
export interface ViewerMemberDocument extends MemberDocument {
  url: string
  source: DocumentSource.DOCUMENT_CENTER
  kind: DocumentKind.MEMBER_DOCUMENT
}

export interface ViewerIncomingFaxDocument extends IncomingFaxDocument {
  url: string
  source: DocumentSource.INCOMING_FAX_DOCUMENT
  member_id?: number
  kind: DocumentKind.INCOMING_FAX_DOCUMENT
}

export interface ViewerOutgoingFaxDocument extends OutgoingFaxDocument {
  url: string
  source: DocumentSource.OUTGOING_FAX_DOCUMENT
  member_id?: number
  kind: DocumentKind.OUTGOING_FAX_DOCUMENT
}

export interface AnticipatedOutgoingFaxDocument {
  blob?: Blob
  receiver_number: string
  fax_cover_page: TemplateResponse
  member_document_id: string
  member_id: number
  episode_id: string
  fax_target_type?: FaxTargetType
}

export interface ViewerAnticipatedOutgoingFaxDocument
  extends AnticipatedOutgoingFaxDocument {
  source: DocumentSource.OUTGOING_FAX_DOCUMENT
  url: undefined
  name: undefined
  id: undefined
  kind: DocumentKind.ANTICIPATED_OUTGOING_FAX
}

export type ViewerDocument =
  | ViewerIHDocument
  | ViewerMemberDocument
  | ViewerAnticipatedOutgoingFaxDocument
  | ViewerIncomingFaxDocument
  | ViewerOutgoingFaxDocument

export type DocumentCenterViewerDocument =
  | ViewerMemberDocument
  | ViewerIncomingFaxDocument
  | ViewerOutgoingFaxDocument

export const isDocumentCenterDocument = (
  document?: ViewerDocument,
): document is DocumentCenterViewerDocument => {
  const documentCenterKinds = [
    DocumentKind.INCOMING_FAX_DOCUMENT,
    DocumentKind.OUTGOING_FAX_DOCUMENT,
    DocumentKind.MEMBER_DOCUMENT,
  ]

  return documentCenterKinds.some((kind) => kind === document?.kind)
}

export interface DocumentViewerState {
  selectedDocument: ViewerDocument | null
  resolvingDocument: boolean
  autofillingDocument: boolean
}

export const INITIAL_STATE: DocumentViewerState = {
  selectedDocument: null,
  resolvingDocument: false,
  autofillingDocument: false,
}

export const { actions: documentViewerActions, reducer } = createSlice({
  name: '@@documents/viewer',
  initialState: INITIAL_STATE,
  reducers: {
    close(state) {
      state.selectedDocument = null
    },
    viewIHDocument(
      state,
      {
        payload: { document, memberId },
      }: PayloadAction<{
        document: EmeraldTypes.Document
        memberId: number
      }>,
    ) {
      const { id, url } = document
      const emeraldUrl =
        url || `${config.EMERALD_API_URL}/patients/${memberId}/documents/${id}`
      state.selectedDocument = {
        ...document,
        url: emeraldUrl,
        source: DocumentSource.INPUT_HEALTH,
        member_id: memberId,
        kind: DocumentKind.EXTERNAL_DOCUMENT,
      }
      state.resolvingDocument = false
    },
    viewAnticipatedOutgoingFaxDocument(
      state,
      {
        payload: { document },
      }: PayloadAction<{
        document: AnticipatedOutgoingFaxDocument
      }>,
    ) {
      state.selectedDocument = {
        ...document,
        id: undefined,
        name: undefined,
        url: undefined,
        source: DocumentSource.OUTGOING_FAX_DOCUMENT,
        kind: DocumentKind.ANTICIPATED_OUTGOING_FAX,
      }
      state.resolvingDocument = false
    },
    viewMemberDocument(
      state,
      {
        payload: { document },
      }: PayloadAction<{
        document: MemberDocument
      }>,
    ) {
      state.selectedDocument = {
        ...document,
        url: `${config.DOCUMENT_CENTER_URL}/`,
        source: DocumentSource.DOCUMENT_CENTER,
        kind: DocumentKind.MEMBER_DOCUMENT,
      }
      state.resolvingDocument = false
    },
    viewIncomingFaxDocument(
      state,
      {
        payload: { document },
      }: PayloadAction<{
        document: IncomingFaxDocument
      }>,
    ) {
      state.selectedDocument = {
        ...document,
        url: `${config.DOCUMENT_CENTER_URL}/`,
        source: DocumentSource.INCOMING_FAX_DOCUMENT,
        kind: DocumentKind.INCOMING_FAX_DOCUMENT,
      }
      state.resolvingDocument = false
    },
    viewOutgoingFaxDocument(
      state,
      {
        payload: { document },
      }: PayloadAction<{
        document: OutgoingFaxDocument
      }>,
    ) {
      state.selectedDocument = {
        ...document,
        url: `${config.DOCUMENT_CENTER_URL}/`,
        source: DocumentSource.OUTGOING_FAX_DOCUMENT,
        kind: DocumentKind.OUTGOING_FAX_DOCUMENT,
      }
      state.resolvingDocument = false
    },
    resolveAndViewMemberDocument(
      state,
      _action: PayloadAction<{
        memberId: number
        documentId: string
        documentSource: DocumentSource
      }>,
    ) {
      state.resolvingDocument = true
    },
  },
  extraReducers: (builder) => {
    // Update selected document in the viewer when we:
    // - Push an update to DC
    // - Save it to Input Health
    builder.addMatcher(
      isAnyOf(
        memberDocumentsActions.documentUpdated,
        memberDocumentsActions.savedToIh,
      ),
      (state, action) => {
        if (state.selectedDocument?.id === action.payload.document.id) {
          state.selectedDocument = {
            // IH docs can't be updated, the selected & payload document will only be of type MemberDocument
            ...(state.selectedDocument as WritableDraft<ViewerMemberDocument>),
            ...action.payload.document,
          }
        }
      },
    )
  },
})

export default reducer

export const selectDocumentViewerState = (state: ReduxState) =>
  state.documents.viewer

export const selectDocumentId = (state: ReduxState) =>
  selectDocumentViewerState(state).selectedDocument?.id
