import type { InitConfiguration } from '@datadog/browser-core'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum'
import { reactPlugin } from '@datadog/browser-rum-react'

import {
  name as packageJsonName,
  version as packageJsonVersion,
} from '../../package.json'

import config from './config'

const QUERY_PARAM_DENY_LIST = [
  new URL(config.DOCUMENT_CENTER_URL).hostname,
  new URL(config.SCRIBE_DOMAIN).hostname,
]

// exported for testing
export const obfuscateQueryParametersIfNeeded = (url: string) => {
  if (!url) return url

  var urlObj: URL
  try {
    urlObj = new URL(url)
  } catch {
    // not a real url, no need to obfuscate
    return url
  }

  try {
    var needsObfuscation = false
    for (const serviceHost of QUERY_PARAM_DENY_LIST) {
      if (urlObj.hostname === serviceHost) {
        needsObfuscation = true
        break
      }
    }
    if (!needsObfuscation) return url

    urlObj.searchParams.forEach((_, key) => {
      urlObj.searchParams.set(key, '<obfuscated>')
    })

    return String(urlObj)
  } catch {
    // we cannot afford this code to fail
    console.warn('Could not obfuscate url:', url)
    return url
  }
}

/*
/* Shared configuration for Datadog logs collection and RUM
*/
const DATADOG_BASE_CONFIG: Omit<
  InitConfiguration,
  'beforeSend' | 'clientToken'
> = {
  env: config.DATADOG_RELEASE_ENV,
  service: packageJsonName,
  version: 'v' + packageJsonVersion, // we use the prefix to match the release tag
  allowFallbackToLocalStorage: true, // needed to support Electron
  sessionSampleRate: 100,
  telemetrySampleRate: 0,
  trackingConsent: 'granted',
  useSecureSessionCookie: true,
}

if (!!config.DATADOG_RELEASE_ENV && !window.Cypress) {
  datadogLogs.init({
    ...DATADOG_BASE_CONFIG,
    clientToken: 'pub1d5f3b88f29b35fa0ab1af1d294ddfae',
    forwardErrorsToLogs: false,
    forwardConsoleLogs: [], // console logs are too noisy
    forwardReports: ['csp_violation'],
    beforeSend: (log, _context) => {
      // Scrub all console output forcefully,
      // as this is not always done even when disabling forwardConsoleLogs
      if (log.origin === 'console') {
        return false
      }

      return true
    },
  })

  datadogLogs.logger.setLevel('info')

  datadogRum.init({
    ...DATADOG_BASE_CONFIG,
    applicationId: 'b0db63bc-f3c9-438c-a9de-cf12d80ba8eb',
    clientToken: 'pubb2d5b9978b9b113326462b23b8626a89',
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK,
    enablePrivacyForActionName: true,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackUserInteractions: true,
    allowedTracingUrls: [/dialoguecorp/],
    plugins: [reactPlugin({ router: true })],
    beforeSend: (event, _context) => {
      // Redact query parameters for network calls as needed
      if (event.type === 'resource') {
        event.resource.url = obfuscateQueryParametersIfNeeded(
          event.resource.url,
        )
      }

      return true
    },
  })
}
