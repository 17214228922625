// @ts-strict-ignore
import type { NewAdminNote } from '@dialogue/coredata'
import type { MemberNote } from '@dialogue/services/dist/er/model'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import reverse from 'lodash/reverse'
import sortBy from 'lodash/sortBy'

import type { ReduxState } from 'app/redux'

interface State {
  fetching: boolean
  creating: boolean
  updating: boolean
  deleting: boolean
  error: Error | null
  errorText: errorKeys | null
  notes: MemberNote[] | null
}

type PatientAction<T> = PayloadAction<{ patientId: string | number } & T>

export type MemberNotesState = Record<string, State>

const INITIAL_STATE: MemberNotesState = {}

const sortNotes = (notes: MemberNote[]) => {
  return reverse(sortBy(notes, ['important', 'created_at']))
}
export enum Operation {
  fetching = 'fetching',
  creating = 'creating',
  updating = 'updating',
  deleting = 'deleting',
}

export enum errorKeys {
  fetching = 'profileTab.errorFetching',
  creating = 'profileTab.errorCreating',
  updating = 'profileTab.errorUpdating',
  deleting = 'profileTab.errorDeleting',
}

const getPatientState = (state: MemberNotesState, patientId: string | number) =>
  (state[patientId] = state[patientId] ?? {
    fetching: false,
    creating: false,
    updating: false,
    deleting: false,
    error: null,
    errorText: null,
    notes: null,
  })

export const { actions: memberNotesActions, reducer } = createSlice({
  name: '@@medicalProfile/memberNotes',
  initialState: INITIAL_STATE,
  reducers: {
    get(state, { payload: { patientId } }: PatientAction<{}>) {
      const patientState = getPatientState(state, patientId)
      patientState.fetching = true
      patientState.error = null
      patientState.errorText = null
    },
    getSuccess(
      state,
      { payload: { patientId, notes } }: PatientAction<{ notes: MemberNote[] }>,
    ) {
      const patientState = getPatientState(state, patientId)
      patientState.fetching = false
      patientState.notes = notes
      patientState.error = null
    },
    createNote(
      state,
      {
        payload,
      }: PatientAction<{
        note: NewAdminNote
      }>,
    ) {
      const patientState = getPatientState(state, payload.patientId)
      patientState.creating = true
      patientState.error = null
      patientState.errorText = null
    },
    updateNote(
      state,
      {
        payload,
      }: PatientAction<{
        note: MemberNote
      }>,
    ) {
      const patientState = getPatientState(state, payload.patientId)
      patientState.updating = true
      patientState.error = null
      patientState.errorText = null
    },
    deleteNote(
      state,
      {
        payload,
      }: PatientAction<{
        noteId: string
      }>,
    ) {
      const patientstate = getPatientState(state, payload.patientId)
      patientstate.deleting = true
      patientstate.error = null
      patientstate.errorText = null
    },
    createdNote(
      state,
      {
        payload,
      }: PatientAction<{
        note: MemberNote
      }>,
    ) {
      const patientState = getPatientState(state, payload.patientId)
      patientState.creating = false
      patientState.notes = sortNotes([...patientState.notes, payload.note])
    },
    updatedNote(
      state,
      {
        payload,
      }: PatientAction<{
        note: MemberNote
      }>,
    ) {
      const patientState = getPatientState(state, payload.patientId)
      patientState.updating = false
      const { notes } = patientState
      const updatedNoteIndex = notes.findIndex(({ id }) => {
        return id === payload.note.id
      })

      if (updatedNoteIndex !== -1) {
        notes.splice(updatedNoteIndex, 1, payload.note)
      } else {
        notes.push(payload.note)
      }
      patientState.notes = sortNotes(notes)
    },
    deletedNote(
      state,
      {
        payload,
      }: PatientAction<{
        noteId: string
      }>,
    ) {
      const patientState = getPatientState(state, payload.patientId)
      patientState.deleting = false
      const { notes } = patientState
      const deletedNoteIndex = notes.findIndex(({ id }) => {
        return id === payload.noteId
      })

      if (deletedNoteIndex !== -1) {
        notes.splice(deletedNoteIndex, 1)
      }
    },
    failure(
      state,
      {
        payload: { type, error, patientId },
      }: PatientAction<{ error: Error; type: Operation }>,
    ) {
      const patientState = getPatientState(state, patientId)
      patientState[type] = false
      patientState.error = error
      patientState.errorText = errorKeys[type]
    },
  },
})

export default reducer

export const selectMemberNotesFetching = (
  state: ReduxState,
  patientId: string | number,
) => state.medicalProfile.memberNotes[patientId]?.fetching

export const selectMemberNotesCreating = (
  state: ReduxState,
  patientId: string | number,
) => state.medicalProfile.memberNotes[patientId]?.creating

export const selectMemberNotesUpdating = (
  state: ReduxState,
  patientId: string | number,
) => state.medicalProfile.memberNotes[patientId]?.updating

export const selectMemberNotesDeleting = (
  state: ReduxState,
  patientId: string | number,
) => state.medicalProfile.memberNotes[patientId]?.deleting

export const selectMemberNotesError = (
  state: ReduxState,
  patientId: string | number,
) => state.medicalProfile.memberNotes[patientId]?.error

export const selectMemberNotes = (
  state: ReduxState,
  patientId: string | number,
) => state.medicalProfile.memberNotes[patientId]?.notes

export const selectErrorMessage = (
  state: ReduxState,
  patientId: string | number,
) => state.medicalProfile.memberNotes[patientId]?.errorText
