import { useCallback, useEffect, useRef } from 'react'

import type { NewTask, UpdateTask } from '@dialogue/coredata'
import {
  Button,
  Form,
  Input,
  type FormItemProps,
  type FormProps,
  type InputRef,
} from 'antd'
import moment, { type Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { DateTimePicker } from 'app/components/date-time-picker'
import { EpisodePicker } from 'app/components/episode-picker'
import { MemberPicker } from 'app/components/member-picker'
import type { Owner } from 'app/components/tasks/task-owner-select'
import { TaskPriorityInput } from 'app/components/tasks/task-priority-input'
import { TaskLink } from 'app/containers/tasks/link'
import { OwnerField } from 'app/containers/tasks/owner-field'
import { TaskDescripitionInput } from 'app/containers/tasks/task-description-input'
import { VersionedDescriptionInput } from 'app/containers/tasks/versioned-description-input'
import { useVariation } from 'app/hooks'
import { Flags } from 'app/services/feature-flags'
import routes from 'app/services/routes'
import { colors } from 'app/theme'

import { useOpenMemberDocument } from '../documents/document-details/useOpenMemberDocument'
import {
  MemberDocumentsMultiSelect,
  type ForwardRefProps,
  type MemberDocumentsMultiSelectProps,
} from '../documents/member-document-multi-select'

import { PreviousTaskField } from './previous-task-field'

export interface StyledRadioProps {
  $backgroundColor: string
  $fontColor: string
}

const StyledDateTimePicker = styled(DateTimePicker)`
  min-width: 205px;

  &.ant-picker-disabled {
    .ant-picker-input > input {
      color: ${colors.onSurface};
    }
  }
`

const StyledInput = styled(Input)`
  &.ant-input-disabled {
    color: ${colors.onSurface};
  }
`

const SpaceWrap = styled.div`
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
`

const StyledForm = styled(Form)`
  .ant-form-item-label > label.ant-form-item-required:before {
    content: unset;
  }

  .ant-form-item-label > label.ant-form-item-required:after {
    content: ' *';
    color: red;
  }
`

export const getFormName = (formId?: string) => `task-form-${formId || 'new'}`

interface TaskFormProps<Values> extends FormProps<Values> {
  isSubtask?: boolean
}

const defaultTaskKeys = {
  assigneeKey: 'assignee_id',
  teamIdsKey: 'team_ids',
}

export const flattenOwner = (
  owner: Owner | undefined,
  customKeys: Partial<typeof defaultTaskKeys> = {},
) => {
  if (!owner) return {}

  const keys = { ...defaultTaskKeys, ...customKeys }
  return {
    [keys.assigneeKey]: owner.assignee,
    [keys.teamIdsKey]: owner.teams,
  }
}

export const changedValuesOwnerToTaskValues = (changedValues: any) => {
  let clonedValues = structuredClone(changedValues)
  if (changedValues.owner) {
    clonedValues = {
      ...clonedValues,
      ...flattenOwner(changedValues.owner),
    }

    delete clonedValues.owner
  }

  return clonedValues
}

export const TaskForm = <FormType extends NewTask | UpdateTask>({
  disabled,
  initialValues = {},
  isSubtask = false,
  onValuesChange,
  onFinish,
}: TaskFormProps<FormType>) => {
  const { t } = useTranslation()
  const editableDescriptionEnabled = useVariation(
    Flags.taskEditableDescriptions,
    true,
  )
  const showTaskAttachmentForm = useVariation(Flags.taskAttachmentsV2, false)
  const [form] = Form.useForm()
  const memberIdFieldValue = Form.useWatch('member_id', form)
  const episodeIdFieldValue = Form.useWatch('episode_id', form)

  const titleInputRef = useRef<InputRef>(null)

  const { episodeId: episodeIdParam, patientId: memberIdParam } = useParams()
  const isInEpisodeView = !!(episodeIdParam && memberIdParam)
  const showEpisodeLink =
    episodeIdFieldValue && episodeIdParam !== episodeIdFieldValue
  const showProfileLink = !!memberIdFieldValue
  const isNewTask = !initialValues.id
  const disableDescription = disabled || !isNewTask
  const isDescriptionVersioned = editableDescriptionEnabled && !isNewTask

  const disableEpisodePicker =
    !memberIdFieldValue || isInEpisodeView || disabled || isSubtask

  const openMemberDocument = useOpenMemberDocument()

  const handleMemberIdChange = useCallback(
    () => form.setFieldsValue({ episode_id: undefined }),
    [form],
  )

  const dateFormItemProps: Pick<
    FormItemProps,
    'getValueFromEvent' | 'getValueProps'
  > = {
    getValueFromEvent: (date: Moment | null) => date?.toISOString(),
    getValueProps: (date: string | undefined) => ({
      value: date ? moment.tz(date, moment.tz.guess()) : null,
    }),
  }

  useEffect(() => {
    if (initialValues?.id !== form.getFieldValue('id')) {
      form.resetFields()
    }
  }, [initialValues?.id, form])

  const memberDocumentsMultiSelectRef = useRef<ForwardRefProps>(null)

  // Controls opening of "document select" modal, inside of MemberDocumentsMultiSelect
  const handleOpenModal = useCallback(() => {
    memberDocumentsMultiSelectRef.current?.openModal()
  }, [memberDocumentsMultiSelectRef])

  const handleOpenDocument = useCallback<
    NonNullable<MemberDocumentsMultiSelectProps['onDocumentClick']>
  >(
    (id, document) => {
      openMemberDocument(document)
    },
    [openMemberDocument],
  )
  const handleValuesChanges = useCallback<
    NonNullable<FormProps['onValuesChange']>
  >(
    (changedValues, values) => {
      onValuesChange?.(changedValuesOwnerToTaskValues(changedValues), values)
    },
    [onValuesChange],
  )

  const handleFinish = useCallback<NonNullable<FormProps['onFinish']>>(
    (values) => {
      onFinish?.(changedValuesOwnerToTaskValues(values))
    },
    [onFinish],
  )

  useEffect(() => {
    // focus on title only when creating a new task
    if (!initialValues?.id) {
      titleInputRef.current?.focus()
    }
  }, [initialValues?.id])

  return (
    <>
      <StyledForm
        form={form}
        name={getFormName(initialValues?.id)}
        initialValues={initialValues}
        autoComplete="off"
        labelCol={{ sm: { span: 6 }, lg: { span: 5 }, xl: { span: 4 } }}
        labelAlign="left"
        onValuesChange={handleValuesChanges}
        onFinish={handleFinish}
        scrollToFirstError={true}
        disabled={disabled}
        colon={false}
        validateMessages={{
          required: t('form.validation.required'),
        }}
      >
        <Form.Item name="title" rules={[{ required: true }]}>
          <StyledInput
            placeholder={
              isSubtask
                ? t('tasks.properties.placeholders.subtaskName')
                : t('tasks.properties.placeholders.name')
            }
            data-testid="task-name-field"
            data-dd-action-name="task-form-title"
            ref={titleInputRef}
            css={`
              font-size: 18px;
              font-weight: 700;
            `}
          />
        </Form.Item>
        <Form.Item
          name="priority"
          label={t('tasks.properties.labels.priority')}
          rules={[{ required: true }]}
          data-dd-privacy="allow"
        >
          <TaskPriorityInput data-testid="task-priority-field" />
        </Form.Item>
        <OwnerField required disabled={disabled} />
        <Form.Item
          label={
            <span data-dd-privacy="allow">
              {t('tasks.properties.labels.memberId')}
            </span>
          }
          required
        >
          <SpaceWrap>
            <Form.Item name="member_id" noStyle rules={[{ required: true }]}>
              <MemberPicker
                defaultId={initialValues.member_id}
                disabled
                allowClear
                onChange={handleMemberIdChange}
                data-cy="task-member-id-field"
              />
            </Form.Item>
            {showProfileLink && (
              <TaskLink
                label={t('tasks.navigation.linkWarning')}
                to={routes.memberProfile(memberIdFieldValue)}
                ddActionName="task-form:goto:member-profile"
              />
            )}
          </SpaceWrap>
        </Form.Item>
        <Form.Item
          label={
            <span data-dd-privacy="allow">
              {t('tasks.properties.labels.episodeId')}
            </span>
          }
        >
          <SpaceWrap>
            <Form.Item name="episode_id" noStyle>
              <EpisodePicker
                memberId={memberIdFieldValue}
                defaultId={initialValues.episode_id}
                disabled={disableEpisodePicker}
                placeholder={t('tasks.properties.placeholders.episodeId')}
                data-cy="task-episode-id-field"
              />
            </Form.Item>
            {showEpisodeLink && (
              <TaskLink
                label={t('tasks.navigation.linkWarning')}
                to={routes.channel(memberIdFieldValue, episodeIdFieldValue)}
                ddActionName="task-form:goto:episode"
              />
            )}
          </SpaceWrap>
        </Form.Item>
        <Form.Item
          name="scheduled_for"
          label={t('tasks.properties.labels.dueOn')}
          rules={[{ required: true }]}
          data-dd-privacy="allow"
          {...dateFormItemProps}
        >
          <StyledDateTimePicker
            showTime
            data-testid="task-due-on-field"
            data-dd-action-name="task-form-scheduled-for:open"
            value={initialValues.scheduled_for}
          />
        </Form.Item>
        {showTaskAttachmentForm && (
          <Form.Item
            name="document_ids"
            label={t('tasks.properties.labels.attachedDocuments')}
            data-dd-privacy="allow"
            // remove space above button when list is empty:
            css={`
              .ant-form-item-control-input {
                min-height: 0;
              }
              .ant-list-empty-text {
                padding: 0;
              }
            `}
            extra={
              <Button
                type="link"
                onClick={handleOpenModal}
                data-cy="open-templates-picker-button"
                data-dd-privacy="allow"
                data-dd-action-name="task-form:add:document"
                css={{ padding: 0 }}
              >
                {t('tasks.actions.addAttachment')}
              </Button>
            }
          >
            <MemberDocumentsMultiSelect
              ref={memberDocumentsMultiSelectRef}
              onDocumentClick={handleOpenDocument}
              memberId={memberIdFieldValue}
              // to remove the default emptyText icon for List:
              emptyText={<div></div>}
            />
          </Form.Item>
        )}

        <PreviousTaskField
          parentId={initialValues?.parent_id}
          previousTaskId={initialValues?.created_from_id}
        />
        <Form.Item name="description">
          {isDescriptionVersioned ? (
            <VersionedDescriptionInput taskId={initialValues.id} />
          ) : (
            <TaskDescripitionInput
              label={t('tasks.properties.labels.description')}
              placeholder={t('tasks.properties.placeholders.description')}
              disabled={disableDescription}
              data-testid={'task-description-field'}
              data-dd-action-name="task-form-description"
            />
          )}
        </Form.Item>
        <Form.Item name="document_ids" hidden />
        {isSubtask && <Form.Item name="parent_id" hidden />}
      </StyledForm>
    </>
  )
}
