import { produce } from 'immer'
import type { ActionType } from 'typesafe-actions'

import type { CallParticipant, TelephoneState } from 'app/redux/telephone/types'

import type * as actions from './actions'
import { CallParticipantStatus, TelephoneActionTypes } from './types'

export type TelephoneActions = ActionType<typeof actions>

export const INITIAL_STATE: TelephoneState = {
  episodeId: null,
  error: null,
  phoneNumber: null,
  status: null,
  warnings: [],
  windowVisible: false,
  hungUp: false,
  muted: false,
  participants: [],
}

export const reducer = (state = INITIAL_STATE, action: TelephoneActions) =>
  produce(state, (draft): typeof INITIAL_STATE | void => {
    switch (action.type) {
      case TelephoneActionTypes.UPDATE_CALL_STATUS:
        draft.status = action.payload
        break
      case TelephoneActionTypes.UPDATE_CALL_ERROR:
        draft.error = action.payload
        break
      case TelephoneActionTypes.ADD_WARNING:
        draft.warnings.unshift(action.payload)
        break
      case TelephoneActionTypes.REMOVE_WARNING:
        draft.warnings = draft.warnings.filter(
          (warning) => warning !== action.payload,
        )
        break
      case TelephoneActionTypes.SET_NUMBER:
        draft.phoneNumber = action.payload
        break
      case TelephoneActionTypes.SHOW_WINDOW:
        draft.episodeId = action.payload
        draft.windowVisible = true
        break
      case TelephoneActionTypes.HIDE_WINDOW:
        return INITIAL_STATE
      case TelephoneActionTypes.CALL_ENDED:
        draft.status = null
        draft.muted = false
        draft.participants = []
        if (draft.windowVisible) draft.hungUp = true
        break
      case TelephoneActionTypes.REDIAL:
        draft.hungUp = false
        break
      case TelephoneActionTypes.TOGGLE_MUTE:
        draft.muted = !draft.muted
        break
      case TelephoneActionTypes.PARTICIPANT_ADDED: {
        const { phoneNumber, participant } = action.payload
        const participantObject: CallParticipant = {
          conferenceSid: participant.conference_sid,
          callSid: participant.call_sid,
          isMuted: participant.muted,
          isOnHold: participant.hold,
          phoneNumber,
          status: CallParticipantStatus.DIALING,
        }

        const existingParticipantIdx = draft.participants.findIndex(
          (p) => p.phoneNumber === phoneNumber,
        )

        // If the participant was not already in the list, simply append them
        if (existingParticipantIdx === -1) {
          draft.participants.push(participantObject)
        } else {
          // Otherwise replace the existing entry
          // This is to avoid shuffling the order when participants are re-added after disconnecting
          draft.participants[existingParticipantIdx] = participantObject
        }
        break
      }
      case TelephoneActionTypes.PARTICIPANT_JOINED:
        draft.participants = draft.participants.map((participant) =>
          participant.callSid === action.payload.CallSid
            ? {
                ...participant,
                isMuted: action.payload.Muted,
                isOnHold: action.payload.Hold,
                status: CallParticipantStatus.CONNECTED,
              }
            : participant,
        )
        break
      case TelephoneActionTypes.PARTICIPANT_UPDATED:
        draft.participants = draft.participants.map((participant) =>
          participant.callSid === action.payload.CallSid
            ? {
                ...participant,
                isMuted: action.payload.Muted,
                isOnHold: action.payload.Hold,
              }
            : participant,
        )
        break
      case TelephoneActionTypes.PARTICIPANT_LEFT:
        draft.participants = draft.participants.map((participant) =>
          participant.callSid === action.payload.CallSid
            ? {
                ...participant,
                callSid: null,
                isMuted: false,
                isOnHold: false,
                status: CallParticipantStatus.DISCONNECTED,
              }
            : participant,
        )
        break
    }
  })

export default reducer
